<template>
    <v-alert dense
             outlined
             type="error"
             class="error-message">
        Erreur lors de la soumission de la requête. Veuillez actualiser cette page ou <a href="#" @click="reloadPage">cliquer ici</a>
    </v-alert>
</template>

<style>
    .v-application .error--text {
        color: white !important;
    }

    .v-application .error--text a {
        color: white !important;
    }
</style>

<script>
import { mapGetters } from 'vuex';
import { log } from './../helper';

export default {
    name: 'Error',
    methods: {
        reloadPage()
        {
            window.location.reload();
        }
    },
    computed: {
        ...mapGetters( [
            'getContext',
        ] ),
    },
    mounted()
    {
        log(this.getContext, "Error page is mounted.") 
    }
}
</script>

<style scoped>
  @import '../assets/css/common.css';
</style>