<template>
    <div class="height-center bg-blue-floa">
        <component
            v-if="demand || loaderTemplateComponent == 'loader-default'" 
            :is="loaderTemplateComponent"
            :sessions-error="sessionsError"
        ></component>
    </div>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    import { apiRoutes, floaContractClient, redirectToReturnUrl, log, components } from './../helper';
    import Error from './Error.vue';
    import { setTagCommanderVars } from './../tagCommanderHelper';

    export default {
        name: 'Loader',
        components: {
            Error,
            'loader-default': () => import('./loader/LoaderDefault.vue'),
            'loader-blue-floa': () => import('./loader/LoaderBlueFloa.vue'),
        },
        props: {
            isSignbookProcessing: {
                type: Boolean,
                default: false 
            },
        },
        data() {
            return {
                loaderTemplateComponent: '',
                demand: null,
                sessionsError: false,
                whiteLabel: false
            }
        },
        methods: {
            ...mapActions([
                'setSessionId',
                'setCurrentStep',
                'setReturnUrl',
                'setSignbookResponse',
            ]),
            startTmxScript(domain, orgId, sessionId) {
                let tmxScript = document.createElement('script')
                tmxScript.type = "text/javascript";
                tmxScript.innerHTML = `fpt0107.fl("${domain}", "${orgId}", "${sessionId}")`;
                document.head.appendChild(tmxScript);
            },
            stopTmxScript() {
               this.setCurrentStep(components.sign);
            },
            async getSignbookResponse() {
                try {
                    await floaContractClient.post(apiRoutes.signbook, { context: this.getContext }).then((response) => {
                        this.setSignbookResponse(response.data);
                    });
                }
                catch (error) {
                    log(this.getContext, `An error occured trying to get the signbook response. Details: ${JSON.stringify(error)}`, true);
                    redirectToReturnUrl(this.getReturnUrl, "false");
                }

                return;
            },
            getTmxResults() {
                const context = this.getContext;
                log(context, "Getting TMX results for white label.", true);
                floaContractClient.get(apiRoutes.controlsResult, { params: { context } })
                    .then((response) => {
                        if ( response.data.Ok )
                        {
                            this.getSignbookResponse().then(() => {
                                this.setCurrentStep(components.sign);
                            });
                        }
                        else
                        {
                            log(context, "TMX controls failed.", true);
                            redirectToReturnUrl(this.getReturnUrl, "false");
                        } 
                    })
                    .catch((error) =>
                    {
                        log(context, `An error on TMX controls. Details: ${JSON.stringify(error)}`, true);
                        this.sessionsError = true;
                    });
            },
            initTemplateFromTypeProduct(typeProduct) {
                switch (typeProduct) {
                    case 'NEW_CCL_RIB_MULTIPLE_SCALES':
                    case 'NEW_CCL': 
                    case 'NEW_CCL_RIB':
                        this.loaderTemplateComponent = 'loader-blue-floa';
                        break;
                    default:
                        this.loaderTemplateComponent = 'loader-default';
                        break;
                }
            }
        },
        computed: {
            ...mapGetters([
                'getContext',
                'getTCVars',
                'getSessionId',
                'getReturnUrl',
            ]),
        },
        async created()
        {
            const context = this.getContext;

            if (!this.isSignbookProcessing && this.getSessionId === null)
            {
                try {
                    const getDemandResponse = await floaContractClient.get(apiRoutes.demands, { params: { context } });
                    this.demand = getDemandResponse.data;
                    this.initTemplateFromTypeProduct(this.demand.product.typeProduct);

                    if (this.demand.metas !== null) {
                        this.whiteLabel = this.demand.metas.whiteLabel;
                    }

                } catch (error) {
                    log(context, `An error occured trying to get the demand to set the template. Using default template. Details: ${JSON.stringify(error)}`, true);
                    this.initTemplateFromTypeProduct("default");
                }
                log(context, "Loader is displayed.");

                try {
                    this.setReturnUrl((await floaContractClient.get('/returnUrl', { params: { context } })).data.returnUrl);
                }
                catch (error) {
                    log(context, `An error occured trying to get the return url. Details: ${JSON.stringify(error)}`, true);
                    this.sessionsError = true;
                    return;
                }

                if (!this.whiteLabel) {
                   await this.getSignbookResponse();
                }

                if (this.demand.product.typeProduct !== "NEW_CCL") {
                    let performedControls;
                    try {
                        const performedControlsResponse = await floaContractClient.get(apiRoutes.performedControls, { params: { context } });
                        performedControls = performedControlsResponse.data.PerformedControls;
                    } catch (error) {
                        log(context, `An error occured on performed controls. Details: ${JSON.stringify(error)}`, true);
                        this.sessionsError = true;
                        return;
                    }

                    if (!performedControls) {
                        log(context, "No performed controls found, getting new TMX session.");
                        try {
                            const sessionsResponse = await floaContractClient.post(apiRoutes.sessions, {
                                context: context,
                            });
                            const sessionId = sessionsResponse.data.id;
                            this.setSessionId(sessionId);

                            log(context, "Launching TMX controls.");

                            this.startTmxScript(sessionsResponse.data.domain, sessionsResponse.data.orgId, sessionId);

                            if (this.whiteLabel)
                                setTimeout(this.getTmxResults, 1000);
                            else
                                setTimeout(this.stopTmxScript, 5000);
                        }
                        catch (error) {
                            log(context, `An error occured trying to get a new TMX session identifier. Details: ${JSON.stringify(error)}`, true);
                            redirectToReturnUrl(this.getReturnUrl, "false");
                            return;
                        }

                    } else if (this.whiteLabel) {
                        this.getTmxResults();
                    }
                    else {
                        this.setCurrentStep(components.sign);
                    }

                    setTagCommanderVars(this.getTCVars);
                }
                else {
                    this.setCurrentStep(components.sign);
                    setTagCommanderVars(this.getTCVars);
                }
               
            }
            if(this.isSignbookProcessing)
            {
                this.initTemplateFromTypeProduct("default");
                log(this.getContext, "Sign book page is processing." );
                return;
            }
        }
    }
</script>

<style scoped>
  @import '../assets/css/loading-spinner.css';
</style>